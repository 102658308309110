var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid mt-5"},[_c('div',[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.salesOrder.seq_number ?? _vm.salesOrder.code)+" "),(_vm.salesOrder.status === _vm.ORDER_STATUS_DRAFT)?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm"},on:{"click":function($event){return _vm.editSalesOrder()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-edit"})])]):_vm._e(),(
                  _vm.salesOrder.status === _vm.ORDER_STATUS_DRAFT &&
                  _vm.$currentUserCan(_vm.$permissions.PERM_DELETE_SALES_ORDERS)
                )?_c('base-button',{attrs:{"type":"danger","icon":"","size":"sm"},on:{"click":function($event){return _vm.deleteSalesOrder()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e(),(
                  _vm.salesOrder.items.length > 0 &&
                  _vm.salesOrder.status === _vm.ORDER_STATUS_DRAFT
                )?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.validateSalesOrder()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"ni ni-check-bold"}),_vm._v(" "+_vm._s(_vm.$t("SALES_ORDERS.VALIDATE"))+" ")])]):_vm._e(),(_vm.salesOrder.status === _vm.ORDER_STATUS_VALIDATED)?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.revertToDraft()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-undo"}),_vm._v(" "+_vm._s(_vm.$t("SALES_ORDERS.REVERT_TO_DRAFT"))+" ")])]):_vm._e(),(
                  _vm.salesOrder.status == _vm.ORDER_STATUS_VALIDATED &&
                  _vm.salesOrder.invoicing_status != _vm.INVOICING_STATUS_INVOICED
                )?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm","disabled":_vm.loading},on:{"click":() => {
                    _vm.salesOrderInvoiceFormModalOpened = true;
                  }}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"ni ni-single-copy-04"}),_vm._v(" "+_vm._s(_vm.$t("SALES_ORDERS.ADD_INVOICE"))+" ")])]):_vm._e(),(
                  _vm.salesOrder.status == _vm.ORDER_STATUS_VALIDATED &&
                  _vm.salesOrder.delivery_status != _vm.DELIVERY_STATUS_DELIVERED
                )?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm","disabled":_vm.loading},on:{"click":() => {
                    _vm.salesOrderDeliveryFormModalOpened = true;
                  }}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fa fa-truck"}),_vm._v(" "+_vm._s(_vm.$t("SALES_ORDERS.ADD_DELIVERY"))+" ")])]):_vm._e(),(_vm.canCancelSalesOrder)?_c('base-button',{attrs:{"type":"danger","icon":"","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.cancelSalesOrder()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"ni ni-fat-remove"}),_vm._v(" "+_vm._s(_vm.$t("SALES_ORDERS.CANCEL"))+" ")])]):_vm._e(),(_vm.salesOrder.status !== _vm.ORDER_STATUS_DRAFT)?_c('base-button',{attrs:{"type":"info","icon":"","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.print()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fa fa-file-pdf"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.PRINT"))+" ")])]):_vm._e(),(_vm.salesOrder.status !== _vm.ORDER_STATUS_DRAFT)?_c('base-button',{attrs:{"type":"success","icon":"","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.toggleMailModal()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fa fa-envelope"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.SEND"))+" ")])]):_vm._e()],1)]),_c('div',{staticClass:"col-4 text-right"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.RETURN_TO_LIST"))+" ")]),_c('notification-subscription',{attrs:{"objectType":'sales-orders',"objectId":_vm.salesOrder.id,"events":{
                UPDATE: _vm.$t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: _vm.$t('NOTIFICATIONS.EVENT_DELETE'),
              }}})],1)])]),_c('div',[_c('div',{staticClass:"col-12 justify-content-center justify-content-sm-between flex-wrap tabs-wrapper"},[_c('tabs',{key:_vm.renderKey,staticClass:"flex-column flex-md-row",attrs:{"fill":"","tabNavWrapperClasses":"nav-wrapper","tabNavClasses":"nav nav-pills nav-fill","value":"details"}},[_c('card',{attrs:{"shadow":""}},[_c('tab-pane',{attrs:{"title":"global","id":"1","active":false}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"ni ni-cloud-upload-96"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.GLOBAL"))+" ")]),_c('sales-order-view-global',{attrs:{"salesOrder":_vm.salesOrder}})],1),(
                  [
                    _vm.CLIENT_VALIDATED_STATUS_VALIDATED,
                    _vm.CLIENT_VALIDATED_STATUS_CANCELED,
                  ].includes(_vm.salesOrder.client_validated_status)
                )?_c('tab-pane',{attrs:{"title":"client-validation","id":"2","active":false}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"ni ni-circle-08"}),_vm._v(" "+_vm._s(_vm.$t("SALES_ORDERS.CLIENT_VALIDATION"))+" ")]),_c('sales-order-view-client-validation',{attrs:{"salesOrder":_vm.salesOrder}})],1):_vm._e(),_c('tab-pane',{attrs:{"title":"details","id":"3","active":false}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"ni ni-bullet-list-67"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.DETAILS"))+" ")]),_c('sales-order-view-details',{attrs:{"salesOrder":_vm.salesOrder},on:{"salesOrderItemsUpdated":_vm.get}})],1),(
                  _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_SALES_INVOICES)
                )?_c('tab-pane',{attrs:{"title":"details","id":"4","active":false}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"ni ni-single-copy-04"}),_vm._v(" "+_vm._s(_vm.$t("SALES_ORDERS.SALES_INVOICES"))+" ")]),_c('sales-order-view-invoices',{attrs:{"salesOrder":_vm.salesOrder},on:{"salesOrderInvoicesUpdated":_vm.get}})],1):_vm._e(),(
                  _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_SALES_DELIVERIES)
                )?_c('tab-pane',{attrs:{"title":"details","id":"5","active":false}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa fa-truck"}),_vm._v(" "+_vm._s(_vm.$t("SALES_ORDERS.DELIVERIES_STATE"))+" ")]),_c('sales-order-view-delivery-state',{attrs:{"salesOrder":_vm.salesOrder},on:{"salesOrderDeliveriesUpdated":_vm.get}})],1):_vm._e(),(
                  _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_SALES_DELIVERIES)
                )?_c('tab-pane',{attrs:{"title":"details","id":"6","active":false}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa fa-truck"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.SALES_DELIVERIES"))+" ")]),_c('sales-order-view-deliveries',{attrs:{"salesOrder":_vm.salesOrder},on:{"salesOrderDeliveriesUpdated":_vm.get}})],1):_vm._e(),(
                  _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_FILES) &&
                  _vm.salesOrder.organization
                )?_c('tab-pane',{attrs:{"title":"files","id":"998"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"ni ni-folder-17"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.FILES"))+" ")]),_c('list-file-component',{attrs:{"object":_vm.salesOrder}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOGS))?_c('tab-pane',{attrs:{"title":"logs","id":"999"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa fa-file"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.LOGS"))+" ")]),_c('sales-order-view-logs',{attrs:{"salesOrder":_vm.salesOrder}})],1):_vm._e()],1)],1)],1)])],2)],1),(!_vm.salesOrder.deliveryItemsState.orderDelivered)?_c('sales-order-view-add-delivery-form',{attrs:{"salesOrder":_vm.salesOrder,"showModal":_vm.salesOrderDeliveryFormModalOpened},on:{"closeSalesOrderDeliveryForm":() => {
        _vm.salesOrderDeliveryFormModalOpened = false;
      }}}):_vm._e(),(!_vm.salesOrder.invoicingItemsState.orderInvoiced)?_c('sales-order-view-add-invoice-form',{attrs:{"salesOrder":_vm.salesOrder,"showModal":_vm.salesOrderInvoiceFormModalOpened},on:{"closeSalesOrderInvoiceForm":() => {
        _vm.salesOrderInvoiceFormModalOpened = false;
      }}}):_vm._e(),_c('modal',{attrs:{"show":_vm.showMailModal,"modal-classes":"modal-secondary","size":"xl"}},[_c('sales-order-mail-modal-content',{attrs:{"salesOrder":_vm.salesOrder,"get":_vm.get,"toggleMailModal":_vm.toggleMailModal}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }